import { Employee } from "./Employee";
import { IDocumentWithTimestamps, ObjectId } from "./globals";
import { Geolocation } from "./Store";

export interface IUserEmbedded {
    _id: ObjectId;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    username?: string;
    profilePicUrl?: string | null;
    number?: string;
    countryCode?: string;
}

export type UserDocumentType =
    | "PERSON"
    | "TABLET"
    | "LOGIN"
    | "KIOSK"
    | "REGISTER"
    | "REFERRAL_CHANNEL"
    | "SNACKTV";

export type Permission =
    | "authorized"
    | "denied"
    | "restricted"
    | "undetermined"
    | "blocked"
    | "granted"
    | "unavailable";

export type ReferralChannelType =
    | "AMBASSADOR"
    | "SIGNAGE"
    | "MESSAGING"
    | "FLYER"
    | "SOCIAL_MEDIA"
    | "USER"
    | "RESTAURANT_KIOSK"
    | "RESTAURANT_QR"
    | "RESTAURANT_ONLINE_ORDERING"
    | null;

export enum ReferralChannel {
    Ambassador = "AMBASSADOR",
    Signage = "SIGNAGE",
    Messaging = "MESSAGING",
    Flyer = "FLYER",
    SocialMedia = "SOCIAL_MEDIA",
    User = "USER",
    RestaurantKiosk = "RESTAURANT_KIOSK",
    RestaurantQr = "RESTAURANT_QR",
    RestaurantOnlineOrdering = "RESTAURANT_ONLINE_ORDERING"
}

export enum CreationSource {
    App = "APP",
    Online = "ONLINE",
    Kiosk = "KIOSK",
    Register = "REGISTER",
    Party = "PARTY",
    Gift = "GIFT",
    Referral = "REFERRAL",
    Peep = "PEEP",
    Other = "OTHER",
    Imported = "IMPORTED",
    AdminRDB = "ADMIN_RDB",
    AdminInvite = "ADMIN_INVITE"
}

export type CardCheckResult = "pass" | "fail" | "unavailable" | "unchecked";

export type Card = {
    cardLast4?: string; // Set for all but zage cards

    // Note: either stripe or checkout id is required
    // (must have AT LEAST one of them, so both cannot be undefined)
    stripeId?: string;
    ocmp?: { pan: string };

    /** @deprecated */
    checkoutId?: string;

    /** @deprecated */
    zage?: boolean;

    isApplePay?: boolean;

    /**
     * Some Stripe cards we have saved were not registered with all the
     * necessary fields (e.g. lacking ZIP or CVC). Those cards will not have
     * this field set.
     *
     * When the app or OO first loads a user's data, any Stripe cards without
     * this field set will be checked against Stripe to verify whether or not
     * that data is present on the card. If it is not, the card will be
     * removed and the user will have to add it again.
     *
     * @deprecated see avsLine1Check, avsZipCheck, cvcCheck instead
     */
    isValidated?: boolean;

    avsLine1Check?: CardCheckResult;
    avsZipCheck?: CardCheckResult;
    cvcCheck?: CardCheckResult;
};

type StoreRole = {
    storeId: string;
    role: string;
};

type LinkedEmployee = {
    storeId: ObjectId;
    employeeId: ObjectId;
};

export interface IUserWithEmployee extends IUser {
    employee: Employee;
}

export interface IUser extends IDocumentWithTimestamps {
    _id: string;
    uid: string | null;
    email?: string;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    username?: string;
    userType: UserDocumentType;
    isStudent?: boolean | null;
    mealPlanSchoolId?: string | null;
    profilePicUrl?: string | null;
    darkMode: boolean;
    isAmbassador: boolean;
    superCodeEnabled: boolean;
    numAmbassadorSocialMediaPosts: number;
    isBanned: boolean;
    banReason?: string;
    address?: string | null;
    number?: string;
    numberHash?: string | null;
    countryCode?: string;
    hasMarketingSMSConsent: boolean;
    requiresSTOPOnSMSReceipts?: boolean | null;
    noEmailReceipts: boolean;
    giftSettingPrivate: boolean;

    numberVerified: boolean;
    isPending: boolean;
    code?: string;
    credit: number;
    geolocation: Geolocation | null;
    geolocationPermission: Permission | null;

    erasureStatus?: "erased";

    purchaseCount: number;

    inboxUnread: number;
    chickenInboxUnread: number;

    lastRegion: string | null;

    notificationPermission: Permission | null;
    playerId?: string | null;

    disableGuestbookConversationsNotifications?: boolean;

    paymentCustomerIds: {
        stripe?: string | null;
        checkout?: string | null;
        ocmp?: { pan: string } | null;
        zage?: string | null;
    };

    activeCardIndex: number | null;
    activeCard: any;

    /** @deprecated */
    customerId: string;
    /** @deprecated */
    activePaymentSourceId: string;

    featurePermissions: {
        tochi?: boolean;
    };

    // `snackpassPermissions` is now slowly being deprecated in favor of `permissions`
    // talk to Max if you need more info
    snackpassPermissions: {
        isAdmin?: boolean;
        isAccountManager?: boolean;
        isAccountExecutive?: boolean;
        team?: boolean;
        isSnackpassEmployee?: boolean;
        isEng?: boolean;
        isSeniorSupport?: boolean;
        hasFeeSettings?: boolean;
        panel?: boolean;
        menu?: boolean;
        accounting?: boolean;
        tochi?: boolean;
        storeIds: string[];
        storeRoles: StoreRole[];
        tabletSerial?: string;
        kioskSerial?: string;
        adminPassword?: string | null;
        // -- RDB permissions -------- //
        hasDashboard?: boolean; //
        hasOrders?: boolean; //
        hasFinancialReports?: boolean; //
        hasMenuEditing?: boolean; //
        hasTopCustomers?: boolean; //
        hasAnalytics?: boolean; //
        hasPromos?: boolean; //
        hasGuestbook?: boolean; //
        hasBilling?: boolean; //
        hasEmployees?: boolean; //
        hasPayroll?: boolean; //
        hasSettings?: boolean; //
        hasSalesReports?: boolean; //
        /** @deprecated */
        hasPayouts?: boolean;
        /** List of IDs of stores this user has payouts permissions for */
        hasPayoutsFor?: string[];
        // -- RDB permissions -------- //
    };

    permissions?: {
        hasDashboard?: ObjectId[];
        hasOrders?: ObjectId[];
        hasFinancialReports?: ObjectId[];
        hasMenuEditing?: ObjectId[];
        hasTopCustomers?: ObjectId[];
        hasAnalytics?: ObjectId[];
        hasPromos?: ObjectId[];
        hasGuestbook?: ObjectId[];
        hasBilling?: ObjectId[];
        hasBillingWrite?: ObjectId[];
        hasEmployees?: ObjectId[];
        hasPayroll?: ObjectId[];
        hasSettings?: ObjectId[];
        hasSettingsWrite?: ObjectId[];
        hasEditAdmin?: ObjectId[];
        hasPayouts?: ObjectId[];
        isOwner?: ObjectId[];
        employeeId?: ObjectId; // this is in process of being deprecated
        employeeIds?: LinkedEmployee[]; // this is an array because one admin can have one employee entry per store
        hasSalesReports?: ObjectId[];
        hasReportsMenu?: ObjectId[];
        hasReportsCustomers?: ObjectId[];
        hasReportsLocations?: ObjectId[];
        hasReportsPromotions?: ObjectId[];
        hasReportsLabor?: ObjectId[];
        hasReportsGiftCards?: ObjectId[];
        hasCatering?: ObjectId[];
        hasDevices?: ObjectId[];
    };

    cards: Card[];

    defaultPaymentProviderId?: "stripe" | "checkout";

    activatedPromotionIds: string[];
    test: {
        testEnabled?: boolean;
        region?: string | null;
        stripeLiveEnabled: boolean;
    };
    rippleEnabled: boolean;
    targetRippleCredit: number;
    sourceRippleCredit: number;
    school: string;
    birthday?: string | null; // Note: this is a date but json doesn't have a "Date" type
    forceBirthdayPrompt?: boolean;
    profileVisibility: {
        showRecentStores: boolean;
        hideFriendFavorites: boolean;
    };
    properties: {
        fanOfStores: string[];
    };

    // Referral fields
    isReferralChannel: boolean;
    referralChannelDescription?: string | null;
    referredFromType: ReferralChannelType;
    referredFromId: ObjectId | boolean;
    referralChannelType: ReferralChannelType;

    // determining user creation source
    creationSource?: CreationSource;
    usedOnline?: boolean;
    usedApp?: boolean;
    usedKiosk?: boolean;
}
