import { IUserEmbedded } from ".";
import { CartAdjustment, IOrderItem } from "./Purchase";

export interface ICartPaymentCard {
    cardLast4: string;
    stripeId?: string;
    checkoutId?: string;
}

export type CartWhen = "NOW" | "LATER";

export enum WhenTypeEnum {
    Now = "NOW",
    Later = "LATER"
}

export interface ICart<ObjectId = string> {
    _id: ObjectId | string;
    purchaseId: ObjectId | string | null;
    user: IUserEmbedded;
    items: IOrderItem[];
    partyId: ObjectId | string;
    storeId: ObjectId | string;
    isCanceled: boolean;
    paymentCard: ICartPaymentCard;
    isAuthorized: boolean;
    isCaptured: boolean;
    authorizedChargeId: string;
    authorizedAmountCents: number;
    isChargeCaptured: boolean;
    tipAmountCents: number;
    cartAdjustments?: CartAdjustment[];

    // Menu dimensions to forward context to @snackpass/menus-sdk
    menu?: {
        hash: string;
        fulfillment: string;
        channel: string;
        temporal: Date;
        langCode: string;
    };
}
