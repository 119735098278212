import { ObjectId, IDocumentWithTimestamps } from "./globals";

export enum ChainGiftCardPayoutType {
    "Separate" = "SEPARATE", // Pays out to each store individually
    "Pooling" = "POOLING" // Pays out to the root store
}

export interface IChain extends IDocumentWithTimestamps {
    _id: ObjectId;
    name: string;
    logoUrl: string | null;
    isFoodHall: boolean | null;
    giftCardPayoutType?: ChainGiftCardPayoutType;
    rootStore?: ObjectId;
}
