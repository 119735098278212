export type ObjectId = string;

export interface IDocumentWithTimestamps {
    createdAt: Date | string;
    updatedAt: Date | string;
}

export const phoneNumberSupport = "(866) 868-2146";

export type Maybe<T> = T | null;

export type Dictionary<T> = Record<string, T>;
