import { Integration } from "./Integrations";
import { POSIntegration } from "./POSIntegrations";
import { FeePolicyWithTotal, Geolocation, IDeliveryRange } from "./Store";

export type DeliveryIntegrationProvider =
    | Integration.Postmates
    | Integration.Doordash
    | POSIntegration.Deliverect
    | "store"; // if the store does the actual delivery

export type DeliveryQuote = {
    provider: DeliveryIntegrationProvider;
    deliveryRange: IDeliveryRange;
    // is null for store deliveries
    quoteId: string | null;
    // list of fees along with which party pays and who receives
    fees: FeePolicyWithTotal[];
    price: number; // in dollars, from the provider
    customerPrice: number; // in dollars, the amount the customer has to pay
    etaMins: number; // in minutes
    // Note: I would like these to be objs with keys for zip, street, etc... but
    // we store as strings and postmates (our first delivery integration) wants strings
    // so we are just going to go with this
    from: string;
    fromGeolocation?: Geolocation;
    to: string;
    toGeolocation?: Geolocation;
};

export type Delivery<ExtraDataT = Record<string, unknown>> = {
    id: string | null;
    price: number;
    provider: DeliveryIntegrationProvider;
    from: string;
    to: string;
    estimatedDropoff: Date | null;
    estimatedPickup: Date | null;
    trackingUrl?: string | null;
    data: ExtraDataT;
};

export type DeliveryInfo = {
    to: string;
    toGeolocation: Geolocation;
    from: string;
    fromGeolocation: Geolocation;
    subtotalCents?: number;
};

export class IntegrationError extends Error {
    provider: Integration;
    message: string;
    code?: string;
    status?: number;

    constructor(
        provider: Integration,
        message: string,
        code?: string,
        status?: number
    ) {
        super();
        this.provider = provider;
        this.message = message;
        this.code = code;
        this.status = status;
    }
}

// POSTMATES

export type PostmatesGetQuoteParams = {
    dropoff_address: string;
    pickup_address: string;
    dropoff_deadline_dt?: string;
    dropoff_latitude?: number;
    dropoff_longitude?: number;
    dropoff_phone_number?: string;
    dropoff_ready_dt?: string;
    pickup_deadline_dt?: string;
    pickup_latitude?: number;
    pickup_longitude?: number;
    pickup_phone_number?: string;
    pickup_ready_dt?: string;
};

// https://postmates.com/developer/docs/#resources__quote__create-quote__responses
export type PostmatesQuote = {
    created: string;
    currency: string;
    currency_type: string;
    dropoff_eta?: string;
    duration: number; // in minutes
    expires: string;
    fee: number; // in cents
    id: string;
    kind: "delivery_quote";
    pickup_duration: number; // in minutes, time the courier arrives at pickup location
};

// All delivery types: https://postmates.com/developer/docs/#resources__delivery__create-delivery__responses
export enum PostmatesManifestSize {
    Small = "small", // ex. bottle of water
    Medium = "medium", // ex. retail bag
    Large = "large", // ex. computer monitor
    XLarge = "xlarge" // ex. groceries -> requires multiple trips to pickup
}

export type PostmatesManifestItem = {
    name: string;
    quantity: number;
    size: PostmatesManifestSize;
};

export type PostmatesCreateDeliveryParams = {
    dropoff_address: string;
    dropoff_name: string;
    dropoff_phone_number: string;
    manifest: string;
    manifest_items: PostmatesManifestItem[];
    pickup_address: string;
    pickup_name: string;
    pickup_phone_number: string;
    dropoff_business_name?: string;
    dropoff_deadline_dt?: string;
    dropoff_latitude?: number;
    dropoff_longitude?: number;
    dropoff_notes?: string; // dropoff instructions
    dropoff_ready_dt?: string;
    manifest_reference?: string;
    pickup_business_name?: string;
    pickup_deadline_dt?: string;
    pickup_latitude?: number;
    pickup_longitude?: number;
    pickup_notes?: string;
    pickup_ready_dt?: string;
    quote_id?: string; // note: recommended to keep consistent
    requires_dropoff_signature?: boolean;
    requires_id?: string;
    undeliverable_action?: string; // ex. "leave_at_door"
    // robo_ are only sandbox
    robo_delivered?: string;
    robo_pickup?: string;
    robo_pickup_complete?: string;
    robo_undeliverable_action?: string;
    robo_undeliverable_reason?: string;
};

export type PostmatesCourier = {
    name: string;
    rating: number;
    vehicle_type: string; // ex. "car" or "van"
    phone_number: string;
    location: { lat: number; lng: number };
    img_href: string;
};

export type PostmatesWaypointInfo = {
    name: string;
    phone_number: string;
    address: string;
    detailed_address: string;
    notes: string;
    location: { lat: number; lng: number };
    verification: { signature: { imageUrl: string } };
};

export enum PostmatesDeliveryStatus {
    Pending = "pending",
    Pickup = "pickup",
    Pickup_Complete = "pickup_complete",
    Dropoff = "dropoff", // moving towards dropoff, doesn't mean it is delivered
    Delivered = "delivered",
    Canceled = "canceled",
    Returned = "returned",
    Ongoing = "ongoing"
}

export type PostmatesDelivery = {
    complete: boolean; // if the delivery is dropped off
    courier: PostmatesCourier;
    courier_imminent: boolean; // close to dropoff or pickup
    created: string;
    currency: string;
    dropoff: PostmatesWaypointInfo;
    dropoff_deadline: string;
    dropoff_eta: string;
    dropoff_identifier: string; // person who received delivery at dropoff
    dropoff_ready: string; // date
    fee: number; // in cents
    id: string;
    kind: "delivery";
    live_mode: boolean;
    manifest_items: PostmatesManifestItem[];
    pickup: PostmatesWaypointInfo;
    pickup_deadline: string;
    pickup_eta: string;
    pickup_ready: string;
    quote_id: string;
    related_deliveries: any[]; // not applicable to us so I don't want to type it
    status: PostmatesDeliveryStatus;
    tip: number;
    tracking_url: string;
    undeliverable_action: string;
    undeliverable_reason: string;
    updated: string;
};
