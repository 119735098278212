import { Dictionary, Maybe } from "./globals";
import { IHoursSchema } from "./Hours";
import { TransactionSource, TransactionSourceTypeEnum } from "./Purchase";

export type MenuType = "standard" | "catering";
export enum MenuTypeEnum {
    Standard = "standard",
    Catering = "catering"
}
export const MenuTypeValues = Object.values(MenuTypeEnum);

export type MenuPriceAdjustment = {
    scope: "items" | "modifiers" | "both";
    value: number;
    applyToOverrides: boolean;
};
export enum MenuPriceAdjustmentScopeTypeEnum {
    Items = "items",
    Modifiers = "modifiers",
    Both = "both"
}
export const MenuPriceAdjustmentScopeTypeValues = Object.values(
    MenuPriceAdjustmentScopeTypeEnum
);

export type MenuPlatform = TransactionSource;
export { TransactionSourceTypeEnum as MenuPlatformTypeEnum };
export const MenuPlatformTypeValues = Object.values(TransactionSourceTypeEnum);

export type MenuCategory = {
    id: string;
    name?: string;
    productIds: string[];
};

export type TaxesOverride = Partial<{
    pickup: number;
    delivery: number;
    dineIn: number;
}>;

export type ProductOverrideFields = {
    price: number | null;
    taxes: TaxesOverride | null;
};

export type AddonGroupOverrideFields = {
    addons: string[];
};

export type AddonOverrideFields = { price: number | null };

export type MenuOverrides = {
    products: Maybe<Dictionary<ProductOverrideFields>>;
    addonGroups: Maybe<Dictionary<AddonGroupOverrideFields>>;
    addons: Maybe<Dictionary<AddonOverrideFields>>;
};

export type Menu = {
    id: string;
    name: string;
    enabled: boolean;
    type: MenuType;
    platforms: MenuPlatform[];
    storeId: string;
    categories: MenuCategory[];
    hours: Maybe<IHoursSchema>;
    priceAdjustment: MenuPriceAdjustment;
    menuOverrides: MenuOverrides;
};
