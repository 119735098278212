/** Supported reader connection types. */
export type ReaderConnectionType =
    | "USB"
    | "Bluetooth"
    | "Internet"
    | "Handoff"
    | "LocalMobileReader";

/**
 * The type of Kiosk.reader
 * This is set during /ping as the currently connected Reader (if any)
 *   - isConnected is set to false if /ping occurs and reader is not connected
 */
export type KioskReader = {
    batteryLevel: number;
    connectionType?: ReaderConnectionType;
    deviceSoftwareVersion: string;
    deviceType: number;
    isConnected?: boolean;
    lastPing?: Date;
    location: ReaderLocation;
    serialNumber: string;
};

/**
 * The type of Kiosk.savedReader
 * This is set on every successful reader connection and used as the last reader serial to attempt reconnection to.
 */
export type SavedReader = {
    connectionType?: string;
    deviceSoftwareVersion?: string;
    serialNumber: string;
};

export type ReaderHistory = {
    deviceSoftwareVersion: string;
    serialNumber: string;
    deviceType?: number;
    batteryLevel?: number;
    location?: ReaderLocation;
    connectionType?: string;
    isConnected?: boolean;
    lastPing?: Date;
};

export enum ReaderUpdateAction {
    ASSIGNED = "ASSIGNED",
    UNASSIGNED = "UNASSIGNED",
    BATTERY = "BATTERY",
    FIRMWARE = "FIRMWARE"
}

export type ReaderLocation = {
    id: string;
    displayName?: string;
    address?: LocationAddress;
};

export type LocationAddress = {
    city: string;
    country: string;
    line1: string;
    line2?: string;
    postalCode: string;
    state: string;
};
