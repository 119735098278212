import { Maybe } from "../utils/types";
import { PartialEmployee } from "./Employee";

export enum ShiftStatus {
    ClockedIn = "CLOCKED_IN",
    ClockedOut = "CLOCKED_OUT",
    StartingPaidBreak = "STARTING_PAID_BREAK",
    StartingUnpaidBreak = "STARTING_UNPAID_BREAK",
    PaidBreak = "PAID_BREAK",
    UnpaidBreak = "UNPAID_BREAK"
}

export type PreSetBreak = {
    id: string;
    hasPaidVariant: boolean;
    hasUnpaidVariant: boolean;
    durationMinutes: number;
};

export enum BreakType {
    PaidBreak = "PAID_BREAK",
    UnpaidBreak = "UNPAID_BREAK"
}

export enum PayPeriodDurationWeeks {
    One = 1,
    Two = 2
}

export enum OvertimeType {
    Daily = "daily",
    DailyDouble = "dailyDouble",
    Weekly = "weekly"
}

export interface IAttendanceSettings {
    payPeriodDurationWeeks: PayPeriodDurationWeeks;
    hasDailyOvertime: boolean;
    dailyOvertimeThreshold: Maybe<number>; // daily hours limit before overtime kicks in
    dailyOvertimeMultiplier: Maybe<number>; // i.e. 1.5
    hasDailyDoubleOvertime: boolean;
    dailyDoubleOvertimeThreshold: Maybe<number>;
    dailyDoubleOvertimeMultiplier: Maybe<number>;
    hasWeeklyOvertime: boolean;
    weeklyOvertimeThreshold: Maybe<number>;
    weeklyOvertimeMultiplier: Maybe<number>;
    preSetBreaks: PreSetBreak[];
    strategy?: AttendancePayPeriodStrategy;
    /** Luxon uses Monday = 1. This is for weekly and biweekly */
    startOfWeek?: number;
    /** This is for monthly and semimonthly */
    startOfMonth?: number;
    sendSMSOnEmployeeAction?: boolean;
    sendSMSOnAdminAction?: boolean;
}

export enum AttendancePayPeriodStrategy {
    Weekly = "weekly",
    BiWeekly = "every2Weeks",
    SemiMonthly = "twiceAMonth",
    Monthly = "monthly"
}

export type Break = {
    id: string;
    type: BreakType;
    start: Date;
    end: Maybe<Date>;
    expectedDuration: number;
    isDeleted: boolean;
    actualDuration: Maybe<number>;
};

export interface IPayPeriod {
    id: string;
    storeId: string;
    startDate: Date;
    endDate: Date;
    dailyOvertimeThreshold: Maybe<number>;
    dailyOvertimeMultiplier: Maybe<number>;
    dailyDoubleOvertimeThreshold: Maybe<number>;
    dailyDoubleOvertimeMultiplier: Maybe<number>;
    weeklyOvertimeThreshold: Maybe<number>;
    weeklyOvertimeMultiplier: Maybe<number>;
}

export interface IShift {
    id: string;
    storeId?: string;
    employeeId: string;
    payPeriodId: string;
    clockedInAt: Date;
    breaks: Break[];
    clockedOutAt: Maybe<Date>;
    cashTips: Maybe<number>;
    wage: number;
    isDeleted: boolean;
    note?: string;
}

export interface IShiftSummary extends IShift {
    regularHours: number;
    overtimeHours: number;
    doubleOvertimeHours: number;
    weeklyOvertimeHours: Maybe<number>;
    totalHours: number;
    estWages: number;
}
export interface EmployeeShiftDetails {
    employee: PartialEmployee;
    shifts: IShiftSummary[];
    regularHours: number;
    overtimeHours: number;
    doubleOvertimeHours: number;
    totalHours: number;
    weeklyOvertimeHours: Maybe<number>;
    estWages: number;
    blendedWage: number;
}
export interface IPayPeriodSummary {
    payPeriodId: string;
    startDate: Date;
    endDate: Date;
    timeCards: number;
    netSales: number;
    regularHours: number;
    overtimeHours: number;
    doubleOvertimeHours: number;
    weeklyOvertimeHours: Maybe<number>;
    estWages: number;
    totalHours: number;
    employeeShiftDetails: EmployeeShiftDetails[];
    employeesWithOngoingShifts: PartialEmployee[];
}
