import { ObjectId, IDocumentWithTimestamps } from "../globals";
import { KioskPaymentProvider } from "../Purchase";
import { Geolocation } from "../Store";

import { KioskReader, SavedReader } from "./Stripe";

export * from "./Stripe";

export interface IKioskStoreEmbedded {
    _id: ObjectId;
    emoji: string;
    name: string;
    phoneNumber: string;
    address: string;
    color: string;
    email: string;
    region: string;
    geolocation: Geolocation;
}

export type KioskDeviceType = "esper" | "stripe";

export type KioskModelType =
    | "A"
    | "B-15"
    | "B-21"
    | "B3"
    | "C"
    | "C2"
    | "D3"
    | "S700";

export enum KioskModelEnum {
    "A" = "Model A",
    "B-15" = 'Model B - 15" (Standard)',
    "B-21" = 'Model B - 21"',
    "B3" = "Model B3",
    "C" = "Model C",
    "C2" = "Model C (Countertop)",
    "D3" = "Model D3 (Register)",
    "S700" = "Stripe S700"
}

/**
 * XXX: Removing keys from this enum will affect server-side mongoose validation.
 * @see src/modules/kiosk/infra/mongo/Kiosk/model.ts line 42 as of 2023-09-18
 */
export enum KioskDeviceTypeEnum {
    /** @deprecated */
    Elo = "elo",
    Esper = "esper",
    Stripe = "stripe"
}

export interface IKiosk extends IDocumentWithTimestamps {
    _id: ObjectId;
    allowCardPayments?: boolean;
    allowCashPayments: boolean;
    allowOtherPayments: boolean;
    appVersion: string;
    deviceId?: string;
    deviceModel?: KioskModelType;
    deviceType: KioskDeviceType;
    employeeMode: boolean;
    lastPing: Date | null;
    paymentProvider: KioskPaymentProvider;
    purchaseCount: number;
    reader?: KioskReader;
    savedReader?: SavedReader | null;
    serial: string;
    store: IKioskStoreEmbedded;

    /**
     * Flag that enables "quiet mode" which disables the kiosk from alerting the user
     * when an order needs action.
     */
    isQuiet?: boolean;

    /**
     * Flag that enables handheld KitchenOS mode
     */
    isHandheldMode?: boolean;

    /**
     * Flag that disables employee pin login on all SnackOS apps
     */
    isPinDisabled?: boolean;

    /**
     * Flag that disables reader
     */
    isReaderDisabled?: boolean;

    /**
     * Supports station level prep, similar to station level printing.
     * A SnackOS device can be assigned to one prep station, and a prep station
     * can be assigned to multiple SnackOS devices.
     */
    prepStation?: string | null;
}
