export enum ConversionSource {
    Kiosk = "kiosk",
    Register = "register",
    OnlineOrdering = "online-ordering"
}

export enum ConversionType {
    AllPromos = "ALL_PROMOS",
    Custom = "CUSTOM",
    Credit = "CREDIT",
    Reward = "REWARD",
    TopPromo = "TOP_PROMO"
}
