import { Maybe } from "../utils/types";

export type Employee = {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    role: string;
    email?: string;
    phone: string;
    pin: string;
    storeId: string;
    permissions?: EmployeePermissions;
    isDeleted: boolean;
    wage?: number;
    identifier?: string;
};

export type EmployeePermissions = {
    // Discounts permission: The permission to manually discount a purchase
    discounts: boolean;

    // Permission to refund/upcharge orders
    orders?: boolean;

    // Permission to manipulate cash drawers
    cashDrawer?: boolean;

    // Permission to view sales
    sales?: boolean;
};

export type EmbedEmployeeSchema = Pick<Employee, "name" | "id">;

export enum EmployeeAuditEventRefType {
    purchase = "Purchase",
    menuItem = "MenuItem",
    register = "Register"
}

export enum EmployeeAction {
    refund = "PURCHASE_REFUNDED",
    partialRefund = "PURCHASE_PARTIALLY_REFUNDED",
    upcharged = "PURCHASE_UPCHARGED",
    acceptCash = "ACCEPTED_CASH_PURCHASE",
    createKioskPurchase = "EMPLOYEE_MODE_KIOSK_PURCHASE",
    manualDiscountKiosk = "EMPLOYEE_MODE_KIOSK_MANUAL_DISCOUNT",
    auditCashDrawer = "AUDITED_CASH_DRAWER",
    viewedSales = "VIEWED_SALES"
}

export enum EmployeeAuditEventSource {
    POS = "pos",
    REGISTER = "register"
}

export enum EmployeeAuthMethod {
    pin = "pin"
}

export type EmployeeAuditEvent = {
    id: string;
    storeId: string;
    ref: string;
    refType: EmployeeAuditEventRefType;
    actor: EmbedEmployeeSchema;
    action: EmployeeAction;
    data: Record<EmployeeAction, any>;
    localTime: string;
    authMethod: EmployeeAuthMethod;
    client: string;
};

export const EMS_PIN_LENGTH = 4;

export type PartialEmployee = {
    firstName?: Maybe<string>;
    id?: Maybe<string>;
    lastName?: Maybe<string>;
    name?: Maybe<string>;
    role?: Maybe<string>;
    wage?: Maybe<number>;
    identifier?: Maybe<string>;
};
