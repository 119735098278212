import { Employee } from "./Employee";
import { Printer } from "./Printer";

export type CashDrawer = {
    id: string;
    name: string;
    store: string;
    printer: string | null;
    device?: string | null;
    totalCashAmount: number;
    createdAt: Date;
};

export enum CashEventType {
    Withdrawal = "WITHDRAWAL",
    Deposit = "DEPOSIT",
    TipWithdrawal = "TIPOUT",
    CashOrder = "ORDERPAID",
    CashRefund = "REFUND",
    Open = "OPEN",
    Audit = "AUDIT",
    SessionStart = "START",
    SessionEnd = "END"
}

export type CashEvent = {
    id: string;
    store: string;
    cashDrawer: string;
    deviceId?: string;
    employee: string | null;
    eventType: CashEventType;
    paidInAmount: number;
    paidOutAmount: number;
    totalAmount: number;
    drawerTotalAmount?: string;
    reason?: string;
    purchase: string | null;
    createdAt: Date;
};

export type CashDrawerWithPrinter = Omit<CashDrawer, "printer"> & {
    printer: Printer | null;
};

export type PopulatedCashEvent = Omit<CashEvent, "cashDrawer" | "employee"> & {
    cashDrawer: CashDrawer;
    employee: Employee | null;
};
