import { IUserEmbedded } from "./User";
import { IDocumentWithTimestamps, ObjectId } from "./globals";
import { ITabOrder } from "./TabOrder";

export enum TabStatusEnum {
    Pending = "PENDING",
    Cancelled = "CANCELLED",
    Closed = "CLOSED"
}

export enum TabClosedByEnum {
    User = "USER",
    Store = "STORE"
}

export enum PaymentTypeEnum {
    Card = "CREDIT/DEBIT",
    Cash = "CASH"
}

export enum PaymentStatusEnum {
    Pending = "PENDING",
    Captured = "CAPTURED",
    Failed = "FAILED"
}

export interface ITabPaymentCard {
    cardLast4: string;
    stripeId: string;
}

export interface ITab extends IDocumentWithTimestamps {
    _id: ObjectId;
    purchaseId: ObjectId | null;
    tabOwner: IUserEmbedded;
    tabOrders: ObjectId[];
    storeId: ObjectId;
    tableNumber: string;
    paymentMethod: PaymentTypeEnum;
    paymentCard: ITabPaymentCard | null;
    paymentStatus: PaymentStatusEnum | null;
    tipAmountCents: number;
    status: TabStatusEnum;
    closedBy: TabClosedByEnum | null;
    startedAt: Date | null;
    pingedAt: Date | null;
    capturedAt: Date | null;
    expiresAt: Date | null;
}

export interface ITabWithPopulatedOrders extends Omit<ITab, "tabOrders"> {
    tabOrders: ITabOrder[];
}
