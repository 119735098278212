import { Maybe } from "../utils/types";
import { Template, TemplateSection } from "./CampaignTemplate";
import { MongoPromotion } from "./Promotion";

export enum CampaignStatus {
    Pending = "PENDING",
    Approved = "APPROVED",
    Rejected = "REJECTED"
}

export enum CampaignAudienceType {
    Followers = "FOLLOWERS",
    NeedsAttention = "NEEDS_ATTENTION",
    NewCustomers = "NEW_CUSTOMERS",
    RecentCustomers = "RECENT_CUSTOMERS",
    Vips = "VIPS",
    Test = "TEST"
}

export enum CampaignHistoryEventType {
    Created = "CREATED",
    Approved = "APPROVED",
    Rejected = "REJECTED",
    Sent = "SENT"
}

export type CampaignHistoryEvent = {
    type: CampaignHistoryEventType;
    userId: Maybe<string>;
    userName: Maybe<string>;
    createdAt: Date;
    updatedAt: Date;
};

export type Campaign = {
    id: string;
    status: CampaignStatus;
    title: string;
    store: { id: string; name: string };
    numberOfUsers: number;
    audienceType: CampaignAudienceType;
    approvedBy: { id: Maybe<string>; name: Maybe<string> };
    history: CampaignHistoryEvent[];
    template: Template;
    sections: TemplateSection[];
    scheduledFor: Maybe<Date>;
    timeZone: string;
    createdAt: Date;
    updatedAt: Date;
    maxUses: Maybe<number>;
    uses: number;
    expirationDate: Maybe<Date>;
    promotion: Maybe<string>;
};

export type HydratedCampaign = Omit<Campaign, "promotion"> & {
    promotion: MongoPromotion;
};
