export type UnknownUnitType = "";
export type EachUnitTypes = "ea" | "dz";
export type WeightUnitTypes = "oz" | "lb" | "mg" | "g" | "kg";
export type VolumeUnitTypes =
    | "ml"
    | "l"
    | "tsp"
    | "Tbs"
    | "fl-oz"
    | "cup"
    | "pnt"
    | "qt"
    | "gal";

export type InventoryUnitType =
    | UnknownUnitType
    | EachUnitTypes
    | WeightUnitTypes
    | VolumeUnitTypes;

export enum InventoryUnit {
    Unknown = "",
    Each = "ea",
    Dozen = "dz",
    Ounce = "oz",
    Pound = "lb",
    Milligram = "mg",
    Gram = "g",
    Kilogram = "kg",
    Millilitre = "ml",
    Litre = "l",
    Teaspoon = "tsp",
    Tablespoon = "Tbs",
    FluidOunce = "fl-oz",
    Cup = "cup",
    Pint = "pnt",
    Quart = "qt",
    Gallon = "gal"
}

export enum WeightUnit {
    Ounce = "oz",
    Pound = "lb",
    Milligram = "mg",
    Gram = "g",
    Kilogram = "kg"
}

export type WeightPurchased = { amount: number; unit: WeightUnitTypes } | null;

export const EachUnits: Record<EachUnitTypes, [string, string]> = {
    [InventoryUnit.Each]: ["Each", "Each"],
    [InventoryUnit.Dozen]: ["Dozen", "Dozen"]
};

export const WeightUnits: Record<WeightUnitTypes, [string, string]> = {
    [InventoryUnit.Ounce]: ["Ounce", "Ounces"],
    [InventoryUnit.Pound]: ["Pound", "Pounds"],
    [InventoryUnit.Milligram]: ["Milligram", "Milligrams"],
    [InventoryUnit.Gram]: ["Gram", "Grams"],
    [InventoryUnit.Kilogram]: ["Kilogram", "Kilograms"]
};

export const VolumeUnits: Record<VolumeUnitTypes, [string, string]> = {
    [InventoryUnit.Millilitre]: ["Millilitre", "Millilitres"],
    [InventoryUnit.Litre]: ["Litre", "Litres"],
    [InventoryUnit.Teaspoon]: ["Teaspoon", "Teaspoons"],
    [InventoryUnit.Tablespoon]: ["Tablespoon", "Tablespoons"],
    [InventoryUnit.FluidOunce]: ["Fluid Ounce", "Fluid Ounces"],
    [InventoryUnit.Cup]: ["Cup", "Cups"],
    [InventoryUnit.Pint]: ["Pint", "Pints"],
    [InventoryUnit.Quart]: ["Quart", "Quarts"],
    [InventoryUnit.Gallon]: ["Gallon", "Gallons"]
};

export const AllUnits: Record<InventoryUnitType, [string, string]> = {
    ...EachUnits,
    ...WeightUnits,
    ...VolumeUnits,
    [InventoryUnit.Unknown]: ["Item", "Items"]
};
