import { IUserEmbedded } from "./User";
import { IDocumentWithTimestamps, ObjectId } from "./globals";
import { IOrderItem } from "./Purchase";

export enum TabOrderStatusEnum {
    Unsent = "UNSENT",
    Sent = "SENT",
    Received = "RECEIVED",
    Started = "STARTED",
    Cancelled = "CANCELLED",
    Completed = "COMPLETED"
}

export interface ITabOrder extends IDocumentWithTimestamps {
    _id: ObjectId;
    user: IUserEmbedded;
    tabId: ObjectId;
    storeId: ObjectId;
    items: IOrderItem[];
    status: TabOrderStatusEnum;
    sentAt: Date | null;
}
