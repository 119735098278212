export interface IIntegrationIds {
    chowly?: string;
    checkmate?: string;
    otter?: string;
}

export interface IIntegration {
    checkmate: {
        enabled?: boolean;
        locationId?: string | null;
    } | null;
    chowly: {
        apiKey?: string | null;
        enabled?: boolean;
        disableOrderWebhook?: boolean;
    } | null;
    otter: {
        enabled?: boolean;
        storeId?: string | null;
    } | null;
    postmates: {
        enabled?: boolean;
    } | null;
    onzway: {
        enabled?: boolean;
    } | null;
    smarpus?: { enabled: boolean; externalId: string | null } | null;
    doordash?: { enabled?: boolean } | null;
}

export enum Integration {
    Checkmate = "checkmate",
    Chowly = "chowly",
    Otter = "otter",
    Postmates = "postmates",
    Onzway = "onzway",
    Smarpus = "smarpus",
    Doordash = "doordash"
}
