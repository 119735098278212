import { ObjectId } from "./globals";

export interface IPOSIntegration {
    chowly: {
        enabled?: boolean;
        apiKey?: string | null;
        apiKeys?: GhostKitchenApiKey[];
    };
    deliverect: {
        enabled?: boolean;
        apiKey?: string | null;
        accountId?: string | null;
        menuSyncTimestamp?: Date;
    };
    ghostKitchen: boolean;
}

export enum POSIntegration {
    Chowly = "chowly",
    Deliverect = "deliverect"
}

export interface GhostKitchenApiKey {
    store: ObjectId | null;
    apiKey: string | null;
}
