export interface Accounting {
    /** The total amounts spent on promotions for Snackpass and the store. */
    totalAmountSpent: {
        snackpass: number;
        store: number;
    };
    /** Whether to void commission on the item. */
    commissionVoidItem: boolean;

    /** Whether to void commission on the entire purchase. */
    voidCommissionOnPurchase: boolean;

    // will have at most one contribution policy
    contributionPolicies: ContributionPolicy[];
}

export interface ContributionPolicy {
    /**
     * `commissionVoid` only applies to the purchases subsidized by this
     * contribution policy. Use the global voidCommission toggle for all
     * purchases this promotion is applied to.
     */
    commissionVoid: boolean;
    snackpassSubsidizationDollars: null | number;
    snackpassSubsidizationPercent: null | number;
    conditions: {
        redemptions: null | {
            max: number;
            min: number;
        };
        dollarsDiscounted: null | {
            max: number;
            min: number;
        };
    };
}

// Based off of reserved fees located here:
// https://docs.google.com/spreadsheets/d/1sNoK2JqAUyGp3L-EEUiihoIGn-8JImKpwGSY5m4YLig/edit#gid=0
export enum ReservedFeeEnum {
    // customer -> store
    DeliveryFee1P = "1P_DELIVERY_STORE_FEE",
    // customer -> snackpass
    DeliveryTip3P = "3P_DELIVERY_TIP_FEE",
    // customer -> snackpass
    DeliveryFee3P = "3P_DELIVERY_FEE",
    // customer -> snackpass. a service fee separate from the flat fee we charge customer.
    // ex. $0.99 service fee + $2.99 delivery fee
    DeliveryServiceFee3P = "3P_DELIVERY_SERVICE_FEE",
    // store -> snackpass
    DeliveryCommission3P = "3P_DELIVERY_COMMISSION",
    // store -> snackpass
    DeliveryTipCommission3P = "3P_DELIVERY_TIP_COMMISSION",
    // snackpass -> integration
    DeliveryCost3P = "3P_DELIVERY_COST",
    // snackpass -> integration
    DeliveryTipCost3P = "3P_DELIVERY_TIP_COST",
    // store -> snackpass. for adjustments to what a store owes snackpass for 3P delivery
    DeliveryStoreCommissionAdjustment3P = "3P_DELIVERY_STORE_COMMISSION_ADJUSTMENT",
    // store -> snackpass
    Commission = "COMMISSION",
    // store -> snackpass
    CommissionFlat = "COMMISSION_FLAT",
    // store -> snackpass
    CreditCardCommission = "CREDIT_CARD_COMMISSION",
    ProcessingFee = "PROCESSING_FEE",
    ProcessingFeeFlat = "PROCESSING_FEE_FLAT",
    // snackpass -> provider
    CreditCardCost = "CREDIT_CARD_COST",
    // snackpass -> provider
    PaymentProcessingCost = "PAYMENT_PROCESSING_COST",
    // store -> snackpass
    /** @deprecated in favor of ConvenienceStoreFee */
    ConvenienceFee = "CONVENIENCE_FEE",
    // customer -> store
    ConvenienceStoreFee = "CONVENIENCE_STORE_FEE",
    // customer -> snackpass
    SnackpassConvenienceFee = "SNACKPASS_CONVENIENCE_FEE",
    // customer -> snackpass
    OnlineOrderingConvenienceFee = "ONLINE_ORDERING_CONVENIENCE_FEE",
    // customer -> store
    OnlineOrderingConvenienceStoreFee = "ONLINE_ORDERING_CONVENIENCE_STORE_FEE",
    // customer -> snackpass
    KioskConvenienceFee = "KIOSK_CONVENIENCE_FEE",
    // customer -> store
    KioskConvenienceStoreFee = "KIOSK_CONVENIENCE_STORE_FEE",
    // store -> snackpass.
    FaxCommission = "FAX_COMMISSION",
    // customer -> store
    SmallOrderDeliveryStoreFee = "SMALL_ORDER_DELIVERY_STORE_FEE",
    // customer -> store
    DeliveryStoreFee1P = "1P_DELIVERY_STORE_FEE",
    // customer -> store
    DeliveryTipStoreFee1P = "1P_DELIVERY_TIP_STORE_FEE",
    // customer -> store
    BagStoreFee = "BAG_STORE_FEE",
    // customer -> store
    DisposableCupStoreFee = "DISPOSABLE_CUP_STORE_FEE",
    // customer -> store
    AlcoholStoreFee = "ALCOHOL_STORE_FEE",
    // customer -> snackpass
    FoodHallFee = "FOOD_HALL_FEE"
}
