import { PrepStation } from "./PrepStation";

export enum PrinterModel {
    EPSON = "epson",
    STAR = "star"
}

export enum PrinterModelType {
    TM_M30II = "TM-m30II",
    TM_M30I = "TM-m30I",
    TM_L90 = "TM-L90",
    TM_L100 = "TM-L100"
}

export type PrinterModelTypeLabel = PrinterModelType.TM_L90;

export enum PrinterFormat {
    RECEIPT = "RECEIPT",
    CHIT = "CHIT",
    LABEL_IMAGE = "LABEL_IMAGE",
    LABEL_V2 = "LABEL_V2",
    CUSTOMER = "CUSTOMER"
}

export type Printer = {
    id: string;
    store: string;
    prepStation: string | null;
    name: string;
    isActive: boolean;
    format: PrinterFormat;
    printerModel: PrinterModelType;
    serial: string;
    lastPing: Date | null;
    asbStatus: number | null;
    status: string | null;
    isAutoPrint: boolean;
    numberOfPrints?: number | null;
    displayModifierGroups?: boolean;
};

export type PrinterWithPrepStation = Omit<Printer, "prepStation"> & {
    prepStation?: PrepStation | null;
};

export enum PrinterUpdateAction {
    CREATED = "CREATED",
    DEPRECATED = "DEPRECATED",
    CLONED = "CLONED",
    REGISTERED = "REGISTERED",
    DISCONNECTED = "DISCONNECTED",
    FLAKY = "FLAKY",
    CONNECTED = "CONNECTED"
}
