import { IDocumentWithTimestamps } from "./globals";
import { Geolocation } from "./Store";

export type SalesTaxRemitter = "store" | "snackpass";

export interface IRegion extends IDocumentWithTimestamps {
    _id: string;
    name: string;
    slug: string;
    geolocation: Geolocation;
    tz: string;
    taxRate?: number;
    state: string;
    marketplaceRegion?: boolean;
    referralCredit: number;
    remitter?: SalesTaxRemitter;
}

// Snackpass Timezones...
export enum SnackpassTimezoneEnum {
    newYork = "America/New_York",
    chicago = "America/Chicago",
    denver = "America/Denver",
    phoenix = "America/Phoenix",
    la = "America/Los_Angeles",
    honolulu = "Pacific/Honolulu",
    juneau = "America/Juneau",
    indianapolis = "America/Indiana/Indianapolis"
}

export const baseTimezone = SnackpassTimezoneEnum.newYork;
