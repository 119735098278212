import { PaymentProvider } from "./Purchase";

export type RefundRecordType =
    | "PaymentRefund"
    | "StoreCreditRefund"
    | "GiftCardCreditRefund"
    | "GlobalCreditRefund"
    | "UntrackedRefund"
    | "InStoreChargeRefund";

export interface IRefundRecord {
    _amount: number;
    refundedAt: Date;
    refundType: RefundRecordType;
}

export interface IPaymentRefund extends IRefundRecord {
    refundType: "PaymentRefund";
    paymentProviderId: PaymentProvider;
    chargeId: string;
}

export interface IStoreCreditRefund extends IRefundRecord {
    refundType: "StoreCreditRefund";
}

export interface IGlobalCreditRefund extends IRefundRecord {
    refundType: "GlobalCreditRefund";
}

export interface IGiftCardCreditRefund extends IRefundRecord {
    refundType: "GiftCardCreditRefund";
    giftCardId?: string;
}

export interface IUntrackedRefund extends IRefundRecord {
    refundType: "GlobalCreditRefund";
}

export interface IInStoreChargeRefund extends IRefundRecord {
    refundType: "InStoreChargeRefund";
}

export enum RefundSource {
    POS = "POS",
    Snackface = "Snackface",
    Dashboard = "Dashboard"
}
