/** declares a variable defined, which will hold in later typescript assertions */
export function declareDefined<T>(
    maybeUndefined: T | null | undefined
): asserts maybeUndefined is T {
    // assumes defined
}

/** cast type to NonNullable without casting */
export function asDefined<T>(maybeUndefined: T | null | undefined): T {
    return maybeUndefined as never;
}

/** DANGER: overrides all typescript assertions */
export function declareType<T>(maybeT: unknown): asserts maybeT is T {
    // assumes it is type
}

/** wrapper for NonNullable */
export type NN<T> = NonNullable<T>;
