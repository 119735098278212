import { IDocumentWithTimestamps, ObjectId } from "./globals";

export interface IPeep extends IDocumentWithTimestamps {
    _id: string;
    // The name of the code that activated the Peep. For example, "DONUT_1"
    code: string;
    // The phone number the peep originates from
    phoneNumber?: string;
    // The PeepCode this Peep references
    peepCode: ObjectId;
    // The following fields are duplicated from PeepCode fields because we need history if
    // any of these changes on peepcode, we still need the original log.
    store: ObjectId | null;
    promotion?: ObjectId | null;
    accountManager?: ObjectId | null;
    // Source this Peep originates from
    source: PeepSource;
}

export interface IPeepCode extends IDocumentWithTimestamps {
    _id: string;
    // The name of the code that activates a Peep. For example, "DONUT_1"
    code: string;
    // which store this peep code links to
    store: ObjectId | null;
    source: PeepSource;
    // description is optional but useful for internal ops and marketing to distinguish between codes
    description?: string | null;
    // which promotion this peep code links to
    promotion?: ObjectId | null;
    // number of times this peep code has been used
    numPeeps?: number;
    accountManager?: ObjectId | null;
    isArchived?: boolean;
    autoGenerated?: boolean;
}

export enum PeepSource {
    Qr = "QR",
    AFrame = "AFRAME",
    Braze = "BRAZE",
    Card = "CARD",
    CounterSign = "COUNTER_SIGN",
    Email = "EMAIL",
    EmployeeReferral = "EMPLOYEE_REFERRAL",
    Facebook = "FACEBOOK",
    Flyer = "FLYER",
    GoogleBusinessListing = "GOOGLE_BUSINESS_LISTING",
    GoogleMaps = "GOOGLE_MAPS",
    GooglePages = "GOOGLE_PAGES",
    GoogleSearch = "GOOGLE_SEARCH",
    Instagram = "INSTAGRAM",
    Kiosk = "KIOSK",
    OnlineOrdering = "ONLINE_ORDERING",
    RestaurantOrderButton = "RESTAURANT_ORDER_BUTTON",
    RestaurantWebsite = "RESTAURANT_WEBSITE",
    Sign = "SIGN",
    TableSticker = "TABLE_STICKER",
    Twitter = "TWITTER",
    Voicemail = "VOICEMAIL"
}
