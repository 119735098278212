import { TransactionSource } from "./Purchase";

export type ReportData = {
    adjustments: number;
    cartTotal: number;
    commission: number;
    creditCard: number;
    deliveryFee: number;
    /** ISO String */
    endDate: string;
    fax: number;
    globalCredit: number;
    /** cash acceptance */
    earnings: number;
    netPayout: number;
    orders: number;
    refundedAmount: number;
    snackpassContribution: number;
    /** ISO String */
    startDate: string;
    snackpassConvenienceFee: number;
    storeCommission3P: number;
    storeCredit: number;
    storeFees: number;
    storeTaxPaidOut: number;
    storeTaxWithheld: number;
    subtotal: number;
    tax: number;
    taxToBeRemittedForStore: number;
    tip: number;
    total: number;
    netSales: number;
    customerToSnackpassFees: number;
    expectedPayout: number;
    expectedCashCollected: number;
};

export enum ReportGranularity {
    day = "day",
    month = "month",
    week = "week"
}

export enum ReportType {
    store = "store"
}

export enum DataType {
    storeHealth = "store_health",
    storePurchase = "store_purchase"
}

export type IPurchaseReport = {
    createdAt: Date;
    updatedAt: Date;
    data: ReportData;
    dataType: DataType;
    endDate: string;
    granularity: ReportGranularity;
    metadata: {
        storeId: string;
        transactionSource?: TransactionSource;
    };
    startDate: Date;
    type: ReportType;
    _id: string;
};
