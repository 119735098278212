import { SalesTax } from "./sub";

export enum TaxPolicyEventType {
    setTaxRate = "SET_TAX_RATE"
}

export type TaxPolicyEvent = {
    type: TaxPolicyEventType;
    taxInfo: SalesTax;
};

export type TaxPolicy = {
    priority: number;
    conditions: any;
    events: TaxPolicyEvent[];
};
