export const TWILIO_BUSINESS_TYPES = [
    "Sole Proprietorship",
    "Partnership",
    "Limited Liability Corporation",
    "Co-operative",
    "Non-profit Corporation",
    "Corporation"
] as const;

export const TWILIO_JOB_POSITIONS = [
    "Director",
    "GM",
    "VP",
    "CEO",
    "CFO",
    "General Counsel",
    "Other"
] as const;

export enum TwilioBrandRegistrationStatus {
    PENDING_SUBMISSION = "PENDING_SUBMISSION",
    PENDING_BRAND_REVIEW = "PENDING_BRAND_REVIEW",
    PENDING_CAMPAIGN_REVIEW = "PENDING_CAMPAIGN_REVIEW",
    BRAND_REVIEW_FAILED = "BRAND_REVIEW_FAILED",
    CAMPAIGN_REVIEW_FAILED = "CAMPAIGN_REVIEW_FAILED",
    APPROVED = "APPROVED"
}
