import { IReward, IUserEmbedded, IStoreEmbedded } from "./";
import { ObjectId } from "./globals";

export type UserType = "User" | "PendingUser";

export enum FavoriteStatus {
    ManualFavorite = "MANUAL_FAVORITE",
    ManualUnfavorite = "MANUAL_UNFAVORITE",
    AutoFavorite = "AUTO_FAVORITE",
    OriginFavorite = "ORIGIN_FAVORITE"
}
export interface IPunchcard {
    _id: string;
    store: IStoreEmbedded;
    user?: IUserEmbedded;
    userId: ObjectId;
    userType: UserType;
    totalPurchases: number;
    totalAmountSpent: number;
    credit: number;
    pointsBalance: number;
    pointsTotal: number;
    createdAt: string;
    updatedAt: string;
    rewards: IReward[];
    complimented?: boolean;
    lastPurchase: ObjectId | null;
    lastPurchaseDate: Date | null;
    firstPurchaseDate?: Date | null;
    friendPoints: IPunchcardFriend[];
    favoriteStatus?: null | FavoriteStatus;
    chainWidePointsBalance?: number; 
}

export interface IPunchcardFriend {
    user: IUserEmbedded;
    points: number;
}
