import { Maybe } from "../globals";

import { CashDrawerDevice } from "./CashDrawer";
import { KDSDevice } from "./KDS";
import { KioskDevice } from "./Kiosk";
import { OrderHubDevice } from "./OrderHub";
import { PrinterDevice } from "./Printer";
import { SnackTVDevice } from "./SnackTV";

export enum MDMProvider {
    Esper = "ESPER"
}

export enum MDMStatus {
    Synced = "SYNCED",
    SyncPending = "SYNC_PENDING"
}

export enum DeviceType {
    CashDrawer = "CASH_DRAWER",
    KDS = "KDS",
    Kiosk = "KIOSK",
    OrderHub = "ORDER_HUB",
    Printer = "PRINTER",
    SnackTV = "SNACKTV"
}

export type BaseStoreDevice = {
    id: string;
    storeId: string;
    deviceDetailsId: Maybe<string>;
    mdmProvider: Maybe<MDMProvider>;
    mdmDeviceId: Maybe<string>;
    mdmDeviceGroup: Maybe<string>;
    mdmStatus: MDMStatus;
    mdmLastSync: Maybe<Date>;
    serial: Maybe<string>;
    hardwareMake: string;
    hardwareModel: string;
    snackId: Maybe<string>;
    name: string;
    deviceType: DeviceType;
    lastPingAt: Maybe<Date>;
    createdAt: Date;
    updatedAt: Date;
};

export type DeviceEvent = {
    id: Maybe<string>;
    createdAt: Maybe<Date>;
    message: Maybe<string>;
};

export type DeviceStats = {
    batteryPercent: Maybe<number>;
    networkConnectionType: "cellular" | "ethernet" | "other" | "wifi" | "none";
    networkName: Maybe<string>;
};

type BaseDetailedStoreDevice = Omit<BaseStoreDevice, "deviceDetailsId">;

export type StoreDevice = (
    | (BaseDetailedStoreDevice & { deviceDetails: CashDrawerDevice })
    | (BaseDetailedStoreDevice & { deviceDetails: KDSDevice })
    | (BaseDetailedStoreDevice & { deviceDetails: KioskDevice })
    | (BaseDetailedStoreDevice & { deviceDetails: OrderHubDevice })
    | (BaseDetailedStoreDevice & { deviceDetails: PrinterDevice })
    | (BaseDetailedStoreDevice & { deviceDetails: SnackTVDevice })
) & { events: DeviceEvent[]; stats: DeviceStats };
