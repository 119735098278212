import { IStore_Public } from "./Store";
import { ObjectId, IDocumentWithTimestamps, Maybe } from "./globals";

export const MIN_GIFTCARD_AMOUNT_CENTS = 500;
export const MAX_GIFTCARD_AMOUNT_CENTS = 50000;

export type ParticipatingLocations = Array<
    Pick<IStore_Public, "_id" | "name" | "slug" | "address">
>;

export interface DigitalGiftCardCartInfo {
    toName: string;
    toPhone: string;
    code: string;
    note?: string; // Optional
    designURL?: string; // Optional
}

export interface IGiftCard extends IDocumentWithTimestamps {
    id: ObjectId;
    fromName: string;
    fromEmail: string;
    toName?: Maybe<string>; // Only optional for PHYSICAL
    toEmail?: Maybe<string>;
    toPhone?: Maybe<string>; // Only optional for PHYSICAL
    encryptedPin?: Maybe<string>; // Only optional for DIGITAL. If set on DIGITAL then we should respect pin being required to use the gift card.
    note: string;
    fromUserId?: Maybe<string>;
    toUserId?: Maybe<ObjectId>;
    maxCreditCents: number;
    remainingCreditCents: number;
    storeId: ObjectId;
    status: GiftCardStatus;
    code: string;
    expirationDate?: Maybe<Date>;
    activationDate?: Maybe<Date>;
    lastUseDate?: Maybe<Date>;
    stripeChargeId?: Maybe<string>;
    chainId?: Maybe<ObjectId>;
    wasAddedToPayrollOnCreation?: Maybe<boolean>;
    type?: Maybe<GiftCardDiscriminator>;
    designURL?: Maybe<string>; // Only exists on DIGITAL. If provided specifies the image that the sender wanted to show the recipient.
}

export enum GiftCardStatus {
    Inactive = "INACTIVE", // unused
    Active = "ACTIVE", // Only for Physical gift cards, represents that the gift card is loaded and can be used by providing the code and pin.
    Sent = "SENT", // unused, used to represent a state where a digital gift card was sent but not yet "redeemed". Since we auto-redeem digital we never see this.
    Redeemed = "REDEEMED",
    OutOfCredit = "OUT_OF_CREDIT",
    Refunded = "REFUNDED"
}

export enum GiftCardDiscriminator {
    DIGITAL_GIFT_CARD = "DIGITAL_GIFT_CARD",
    PHYSICAL_GIFT_CARD = "PHYSICAL_GIFT_CARD"
}
