import { Maybe } from "../utils/types";

// ==== Campaign Templates ==== //
//
// Campaigns have a template property that has two pieces:
//
// 1) sections: this is a list of sections of the template.
//    these sections can have a type of "BODY" or "TITLE" and then some text.
//    for example, a section could be: { type: "BODY", text: "Welcome {{name}}!" }
//
// 2) variables: this is a list of rich variables that can be injected into the templates
//    for example a variable could be:
//    {
//        id: "Menu Item",
//        placeholder: "menu item",
//        type: "PRODUCT",
//        metadata: { kind: "REWARD", label: "50% off", data: { discount: { percentOff: 50 } }  }
//    }
//    etc... you get the picture. the variables are injected into the sections by replacing
//    the id in the section, .replace("{{Menu Item}}") in this case, with the metadata.label
//    the other data is used when creating rewards etc...
//

export enum TemplateSectionType {
    Body = "BODY",
    Title = "TITLE"
}

export enum VariableType {
    Announcement = "ANNOUNCEMENT",
    Product = "PRODUCT",
    Discount = "DISCOUNT"
}

export enum VariableKind {
    Text = "TEXT",
    Reward = "REWARD",
    Product = "PRODUCT"
}

export enum DiscountType {
    Percent = "PERCENT",
    NewPrice = "NEW_PRICE",
    DollarsOff = "DOLLARS_OFF"
}

export type CampaignTemplateSectionText = string;

export type TemplateSection = {
    type: TemplateSectionType;
    text: CampaignTemplateSectionText;
};

export type TextData = {
    message: string;
};

export type RewardDiscountData = {
    type: DiscountType;
    // Note: these are optional bc graphql types don't require
    // the and undefined is treated like null. could make them forced to be null
    // and then make the GQL <> domain mappers transform it to require null but more
    // work than it is worth right now
    percentOff?: Maybe<number>;
    newPrice?: Maybe<number>;
    dollarsOff?: Maybe<number>;
};

export type RewardDiscountedProductData = {
    name: string;
    productId: string;
    imageUrl: Maybe<string>;
};

export type RewardData = {
    discount: RewardDiscountData;
    storewide: boolean;
    products: RewardDiscountedProductData[];
};

export type ProductData = {
    name: string;
    productId: string;
    imageUrl: Maybe<string>;
};

export type VariableData = {
    kind: VariableKind;
    label: string;
    data: TextData | RewardData | ProductData;
};

export type Variable = {
    variableId: string;
    placeholder: string;
    // this is different from the variable.kind. for instance a variable
    // could be for a product, but the variable could be a reward. ex. could be a new product
    // that has a reward for people. in this case the variable type is a product,
    // but the data for the variable is a reward
    // another example, you could have an announcement for a product, reward, or a message
    // so the variable data is one of those entities but the actual variable is an announcement type
    type: VariableType;
    variableData: VariableData;
};

export type Template = {
    sections: TemplateSection[];
    variables: Variable[];
};
